import * as React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"

const PageTemplate = ({ data, location }) => {
  const page = data.markdownRemark
  const siteTitle = data.site.siteMetadata?.title || `Title`
  const schemaOrgType = page.frontmatter?.schemaOrgType || `WebPage`

  return (
    <Layout location={location} title={siteTitle}>
      <SEO
        title={page.frontmatter.title}
        description={page.frontmatter.description || page.excerpt}
      />
      <article
        className="page"
        itemScope
        itemType={`http://schema.org/${schemaOrgType}`}
      >
        <header>
          <h1 itemProp="headline">{page.frontmatter.title}</h1>
          <section
            dangerouslySetInnerHTML={{ __html: page.html }}
            itemProp="articleBody"
          />
        </header>
      </article>
    </Layout>
  )
}

export default PageTemplate

export const pageQuery = graphql`
  query PageBySlug(
    $id: String!  
  ) {
    site {
      siteMetadata {
        title  
      }
    }
    markdownRemark(id: { eq: $id }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
        description
        schemaOrgType  
      }
    }
  }
`